import { createVNode as _createVNode, resolveComponent as _resolveComponent, createElementVNode as _createElementVNode, unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0c164e2a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "body" }

import DealHead from './components/DealHead.vue'
import HeadUser from './components/HeadUser.vue'
import DealNav from './components/DealNav.vue'
import DealFoot from '@/components/DealFoot.vue'
import { useRouter } from 'vue-router'

export default {
  setup(__props) {

const router = useRouter()

return (_ctx, _cache) => {
  const _component_router_view = _resolveComponent("router-view")

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(DealHead),
    _createVNode(HeadUser),
    _createVNode(DealNav),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_router_view)
    ]),
    (_unref(router).currentRoute.value.path!=='/deal/vip')
      ? (_openBlock(), _createBlock(DealFoot, { key: 0 }))
      : _createCommentVNode("", true)
  ], 64))
}
}

}